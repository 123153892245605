import React from "react";
import style from "./track-entity.module.css";
import { ITrack } from "../../../../models/registration-dependencies/interfaces/tracks";

const TrackEntity = ({track}: {track: ITrack}) => {
    return (
        <section className={style["track-entity"]} data-id={track._id}>
            <article data-role="card">
                <h5>{track?.TrackName}</h5>
            </article>
        </section>
    );
};

export default TrackEntity;
