import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./tool-button.module.css";

const ToolButton = ({ toolIcon, action, title, status = true }: { toolIcon: IconDefinition; action: (e: any) => Promise<void>; title: string, status?: boolean }) => {
    return (
        <section className={style["tool-button"]} title={title} data-status={status}>
            <FontAwesomeIcon icon={toolIcon} onClick={action}></FontAwesomeIcon>
        </section>
    );
};

export default ToolButton;
