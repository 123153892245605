import React from 'react'
import StrategyLoginButton from "../../../components/strategy-login-button/StrategyLoginButton";
import style from "./login.module.css";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const Login = () => {
    const strategies = [
        { name: "Google", icon: faGoogle }
    ];

    const strategyAuth = (strategy: string) => {
        const path = strategy.toLocaleLowerCase();
        window.open(`${process.env.REACT_APP_BACKEND}/auth/${path}`, "_self");
    };

    return (
        <section className={style["login-component"]}>
            <article>
                {strategies.map((strat) => (
                    <StrategyLoginButton
                        key={strat.name}
                        strategyIcon={strat.icon}
                        strategyName={strat.name}
                        strategyOnClick={() => strategyAuth(strat.name)}
                    />
                ))}
            </article>
        </section>
    );
};

export default Login;
