import { Registration } from "../../../../models/registration.model";
import style from "./profile-entity.module.css";
import { faEye, faEyeSlash, faIdCard, faPassport, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import ToolButton from "../../tool-button/ToolButton";

async function deleteRegistration(id: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/registrations/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        }
    });
    if (response.status === 200) {
        const json = await response.json();
        return json.user;
    }
}

async function setRegistrationActive(id: string, value: boolean) {
    return await setFieldValue(id, "isActive", "bool", value);
}

async function setRegistrationGoh(id: string, value: boolean) {
    return await setFieldValue(id, "goh", "bool", value);
}

async function setFieldValue(id: string, fieldName: string, dataType: string, value: string | boolean) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/registrations/${id}/${fieldName}/${dataType}/${value}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        }
    });
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else {
        return null;
    }
}

const handleToolClick = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>, toolName: string, registration: Registration, actions: (() => void)[]) => {
    e.preventDefault();
    e.stopPropagation();

    switch (toolName) {
        case "delete":
            {
                const response = window.confirm("Are you sure you what to delete this record?");
                if (response) {
                    await deleteRegistration(registration._id.toString());
                    actions.forEach((element) => {
                        element();
                    });
                }
            }
            break;

        case "active":
            {
                const response = await setRegistrationActive(registration._id.toString(), !registration.isActive);
                if (response) {
                    actions.forEach((element) => {
                        element();
                    });
                }
            }
            break;

        case "open-registration":
            actions.forEach((element) => {
                element();
            });
            break;

        case "toggle-goh":
            {
                const response = await setRegistrationGoh(registration._id.toString(), !registration.goh);
                if (response) {
                    actions.forEach((element) => {
                        element();
                    });
                }
            }
            break;

        default:
            break;
    }
};

export default function ProfileEntity({
    registration,
    refresh,
    setShowProfileModal,
    setProfileModalRegistration
}: {
    registration: Registration;
    refresh: () => void;
    setShowProfileModal: (value: boolean) => void;
    setProfileModalRegistration: (registration: Registration) => void;
}) {
    const imageSrc = registration.imageUploaded ? "https://register.soonercon.com/api" + registration.imageHref : `/user.png`;
    const imgAttr = registration.imageUploaded ? registration.RegistrationNames.Pseudonym : "unknown";
    return (
        <section className={style.entity} data-entity data-active={registration.isActive}>
            <article
                data-has-bio={registration.BioAndSocials?.bio?.length > 0}
                data-step={registration?.status?.name || "not started"}
                onClick={() => {
                    if (registration?.status?.level >= 8 || registration.BioAndSocials?.bio?.length > 0) {
                        setShowProfileModal(true);
                        setProfileModalRegistration(registration);
                    }
                }}
            >
                <img src={imageSrc} alt={registration.RegistrationNames.Pseudonym} data-guest={imgAttr} />

                <h3>
                    {registration.RegistrationNames.Pseudonym || registration.PanelistContact.FirstName || registration.PanelistContact.LastName
                        ? `${registration.RegistrationNames.Pseudonym ?? registration.PanelistContact.FirstName + " " + registration.PanelistContact.LastName}`
                        : registration.PanelistContact.Email}
                </h3>
                <h3 className={style.email}>{registration.PanelistContact.Email}</h3>
                <div className="title">
                    {registration?.status?.date &&
                        `${new Date(registration.status.date).toLocaleDateString("en-us", {
                            weekday: "long",
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                        })} ${new Date(registration.status.date).toLocaleTimeString("en-US")}`}
                </div>
                <span>{registration.BioAndSocials?.bio?.length > 0 ? "Has Bio" : "Missing Bio"}</span>
                <h6>{registration?.status?.name || "not started"}</h6>
                <div className={style.tools} data-tools>
                    <ToolButton
                        toolIcon={faStar}
                        title="Toggle Guest of Honor"
                        status={registration.goh ?? false}
                        action={(e) => handleToolClick(e, "toggle-goh", registration, [refresh])}
                    ></ToolButton>
                    <ToolButton
                        toolIcon={faPassport}
                        title="Open Personal Info"
                        action={(e) =>
                            handleToolClick(e, "open-registration", registration, [
                                () => window.open(`https://register.soonercon.com/register/${registration.guestId}/personal_info`)
                            ])
                        }
                    ></ToolButton>
                    <ToolButton
                        toolIcon={faIdCard}
                        title="Open Profile"
                        action={(e) =>
                            handleToolClick(e, "open-registration", registration, [
                                () => window.open(`https://register.soonercon.com/register/${registration.guestId}/profile`)
                            ])
                        }
                    ></ToolButton>
                    {(!registration.status || registration.status.level < 2) && (
                        <ToolButton
                            toolIcon={faTrash}
                            title="Delete Registration"
                            action={(e) => handleToolClick(e, "delete", registration, [refresh])}
                        ></ToolButton>
                    )}
                    {registration.status && registration.status.level >= 2 && (
                        <ToolButton
                            toolIcon={registration.isActive === false ? faEyeSlash : faEye}
                            title={registration.isActive === false ? "Activate Registration" : "Deactivate Registration"}
                            action={(e) => handleToolClick(e, "active", registration, [refresh])}
                        ></ToolButton>
                    )}
                </div>
            </article>
        </section>
    );
}
