import React, { useEffect, useState } from 'react'
import style from './all-panels-by-user.module.css'
import { Registration } from '../../../models/registration.model';
import PrintPanelEntity from '../../shared/entities/print-panel/PrintPanelEntity';
import { IUserPanel } from '../../../models/user-panel/IUserPanel';

async function fetchRegistrations(setAction: (json: any) => void) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/allRegistrations`, {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        }
    });
    if (response.status === 200) {
        const json = await response.json();
        setAction(json);
    }
}
async function fetchUserPanels(setAction: (json: any) => void) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/user-panels`, {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        }
    });
    if (response.status === 200) {
        const json = await response.json();
        setAction(json);
    }
}

const getPanelList = (userPanels: IUserPanel[], profileModalRegistration: Registration, registrations: Registration[]) => {
    return userPanels
        .filter((up) => up.creditedAsName === profileModalRegistration.RegistrationNames.Pseudonym)
        .map((pl) => {
            return {
                ...pl,
                users: registrations
                    .filter((r) => pl.allPanelistsButModerator?.split("\n").includes(r.RegistrationNames.Pseudonym))
                    .reduce((acc, reg) => {
                        acc[reg.RegistrationNames.Pseudonym] = reg.PanelistContact.Email;
                        return acc;
                    }, {} as { [key: string]: string })
            };
        });
};

const AllPanelsByUser = () => {
    const [registrations, setRegistrations] = useState([] as Registration[]);
    const [userPanels, setUserPanels] = useState([] as IUserPanel[]);

    useEffect(() => {
        fetchRegistrations(setRegistrations);
        fetchUserPanels(setUserPanels);
    }, []);

    return (
        <section className={style["all-panels"]}>
            {registrations
                .filter(r => r.isActive !== false && userPanels.some(up => up.creditedAsName === r.RegistrationNames.Pseudonym))
                .map((registration, idx) =>
                    (<PrintPanelEntity registration={registration} userPanels={getPanelList(userPanels, registration, registrations)} key={idx}></PrintPanelEntity>))}
        </section>
    )
}

export default AllPanelsByUser
