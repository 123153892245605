import React, { useState } from "react";
import style from "./invite-create.module.css";
import { IInvite } from "../../../../models/invite/invite.interface";
import InviteEntity from "./InviteEntity";
import { Registration } from "../../../../models/registration.model";
import { IInsertedResponse } from "../../../../models/mongo-responses/IInsertedResponse";

const defaultInvite = {
    "Contact Method": "",
    "Credited As Name": "",
    "Email Address1": "",
    "First Name IRL": "",
    "Last Name IRL": "",
    "Panelist City": "",
    "Panelist State": "",
    "Preferred Pronouns": ""
};

const defaultInviteIndexes = {
    "Contact Method": -1,
    "Credited As Name": -1,
    "Email Address1": -1,
    "First Name IRL": -1,
    "Last Name IRL": -1,
    "Panelist City": -1,
    "Panelist State": -1,
    "Preferred Pronouns": -1
};

const insertInviteList = async (inviteList: IInvite[]) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/create-invites`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        },
        body: JSON.stringify(inviteList)
    });
    if (response.status >= 200 && response.status < 300) {
        const json = (await response.json()) as IInsertedResponse;
        return { status: response.status, message: response.statusText, body: json };
    } else {
        return { status: response.status, message: response.statusText, body: { acknowledged: false, insertedCount: 0, insertedIds: {} } };
    }
};

const InviteCreate = ({ list, registrations }: { list: IInvite[], registrations: Registration[] }) => {
    const [step, setStep] = useState(1);
    const [copiedRows, setCopiedRows] = useState([] as string[][]);
    const [newInvites, setNewInvites] = useState([] as IInvite[]);
    const [valueIndexes, setValueIndexes] = useState(defaultInviteIndexes);
    const [singleInvite, setSingleInvite] = useState(defaultInvite);
    const [hasHeader, setHasHeader] = useState(true);

    const getFromClipboard = async () => {
        let clipText: string = null as unknown as string;
        try {
            clipText = await navigator.clipboard.readText();
        } catch (error) {
            alert("Data in clipboard is not copied from a spreadsheet.");
        }

        if (clipText) {
            // split into rows
            const clipRows = clipText.split(String.fromCharCode(13));

            let clipCells = [];
            // split rows into columns
            for (let i = 0; i < clipRows.length; i++) {
                clipCells.push(clipRows[i].split(String.fromCharCode(9)));
            }

            if (clipCells.length && clipCells[0].length > 1) {
                setCopiedRows(clipCells);
                setStep(3);
            } else {
                alert("Data in clipboard is not copied from a spreadsheet.");
            }

            console.log(clipCells);
        }
    };

    const getSingle = () => {
        setStep(2);
    };

    const handleSelectChange = ({ target }: { target: HTMLSelectElement }, prop: keyof IInvite) => {
        const idx = [...target.options].map((o) => o.value).indexOf(prop);
        setValueIndexes({ ...valueIndexes, [prop]: idx });
    };
    const handleInputChange = ({ target }: { target: HTMLInputElement }, prop: keyof IInvite) => {
        setSingleInvite({ ...singleInvite, [prop]: target.value });
    };
    const handleSubmit = async () => {
        switch (step) {
            case 2:
                setNewInvites([singleInvite]);
                setStep(4);
                break;
            case 3:
                setNewInvites(
                    copiedRows
                        .map((row) => {
                            return {
                                "Contact Method": valueIndexes["Contact Method"] === -1 ? "" : row[valueIndexes["Contact Method"]],
                                "Credited As Name": valueIndexes["Credited As Name"] === -1 ? "" : row[valueIndexes["Credited As Name"]],
                                "Email Address1": valueIndexes["Email Address1"] === -1 ? "" : row[valueIndexes["Email Address1"]],
                                "First Name IRL": valueIndexes["First Name IRL"] === -1 ? "" : row[valueIndexes["First Name IRL"]],
                                "Last Name IRL": valueIndexes["Last Name IRL"] === -1 ? "" : row[valueIndexes["Last Name IRL"]],
                                "Panelist City": valueIndexes["Panelist City"] === -1 ? "" : row[valueIndexes["Panelist City"]],
                                "Panelist State": valueIndexes["Panelist State"] === -1 ? "" : row[valueIndexes["Panelist State"]],
                                "Preferred Pronouns": valueIndexes["Preferred Pronouns"] === -1 ? "" : row[valueIndexes["Preferred Pronouns"]]
                            } as IInvite;
                        })
                        .slice(hasHeader ? 1 : 0)
                        .filter((i) => !list.find((l) => l["Email Address1"] === i["Email Address1"]))
                );
                setStep(4);
                break;

            case 4:
                const response = await insertInviteList(newInvites);
                if (response.body.acknowledged) {
                    alert(`Imported ${response.body.insertedCount} invites`);
                } else {
                    alert(response.message);
                }
                break;

            default:
                break;
        }
    };

    return (
        <section className={style["invite-create"]}>
            {(() => {
                switch (step) {
                    case 2:
                        return (
                            <>
                                <article data-role="form">
                                    {Object.keys(defaultInvite).map((k, idx) => (
                                        <>
                                            <label key={`input-${idx}`}>{k}</label>
                                            <input key={`input-${idx}`} onChange={(e) => handleInputChange(e, k as keyof IInvite)}></input>
                                        </>
                                    ))}
                                </article>
                            </>
                        );

                    case 3:
                        return (
                            <>
                                <article className={style.invert} data-role="form">
                                    <label>Copied select has header</label>
                                    <input type="checkbox" onChange={(e) => setHasHeader(e.target.checked)}></input>
                                    {Object.keys(defaultInvite).map((k, idx) => (
                                        <>
                                            <label key={`input-${idx}`}>{k}</label>
                                            <select key={`select-${idx}`} onChange={(e) => handleSelectChange(e, k as keyof IInvite)}>
                                                {copiedRows[0].map((cell: string, idx) => (
                                                    <option key={`option-${idx}`}>{cell}</option>
                                                ))}
                                            </select>
                                        </>
                                    ))}
                                </article>
                            </>
                        );
                    case 4:
                        if (newInvites.length) {
                            return (
                                <>
                                    <article className={style.invert}>
                                        {newInvites.map((invite: IInvite, idx: number) => (
                                            <InviteEntity invite={invite} key={`invite-${idx}`} showDelete={false} refresh={() => {}}></InviteEntity>
                                        ))}
                                    </article>
                                </>
                            );
                        } else {
                            return <article className={`${style.invert} ${style.center}`}>These records have already been imported.</article>;
                        }

                    default:
                        return (
                            <>
                                <button onClick={getFromClipboard}>Create Invites from Clipboard</button>
                                <button onClick={getSingle}>Create Single Entry</button>
                            </>
                        );
                }
            })()}
            <nav>
                <button onClick={() => setStep(1)}> Reset </button>
                <button onClick={handleSubmit}>Submit</button>
            </nav>
        </section>
    );
};

export default InviteCreate;
