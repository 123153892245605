import { faCircleChevronLeft, faCircleChevronRight, faCircleLeft, faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import style from "./grid.module.css";

const Grid = ({ data }: { data: string[][] }) => {
    const pageSize = 30;
    const [start, setStart] = useState(0);
    const [columnCount, setColumnCount] = useState(1);
    const [gridData, setGridData] = useState(data.slice(start, pageSize));

    useEffect(() => {
        console.log(data.slice(start, start + pageSize));
        if (data && data.length) {
            setGridData([data[0], ...data.slice(start + 1, start + pageSize + 1)]);
            setColumnCount(data[0].length);
        }
    }, [start, data]);

    if (!data || !data.length) {
        return <section className={style["report-grid"]}></section>;
    }

    return (
        <section className={style["report-grid"]}>
            <ul data-columns={columnCount}>
                {gridData.map((row, i) =>
                    row.map((cell, idx) => (
                        <li key={`${row[0].replaceAll(" ", "-")}-${i}-${idx}`} data-role={i === 0 ? "header" : "content"}>
                            {cell.trim()}
                        </li>
                    ))
                )}
            </ul>
            <div className={style.tools}>
                <FontAwesomeIcon title="Jump to first page" onClick={() => setStart(0)} icon={faCircleLeft} data-disabled={start === 0}></FontAwesomeIcon>
                <FontAwesomeIcon
                    title="Jump to previous page"
                    onClick={() => setStart(start - pageSize)}
                    icon={faCircleChevronLeft}
                    data-disabled={start === 0}
                ></FontAwesomeIcon>
                <div>
                    page {start / pageSize + 1} of {Math.ceil(data.length / pageSize)}
                </div>
                <FontAwesomeIcon
                    title="Jump to next page"
                    onClick={() => setStart(start + pageSize)}
                    icon={faCircleChevronRight}
                    data-disabled={start === Math.ceil(data.length / pageSize) * pageSize - pageSize}
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                    title="Jump to last page"
                    onClick={() => setStart(Math.ceil(data.length / pageSize) * pageSize - pageSize)}
                    icon={faCircleRight}
                    data-disabled={start === Math.ceil(data.length / pageSize) * pageSize - pageSize}
                ></FontAwesomeIcon>
            </div>
        </section>
    );
};

export default Grid;
