import React, { useState } from "react";
import style from "./panel-create.module.css";
import { IPanel, IPanelWithCategories } from "../../../../models/registration-dependencies/interfaces/panels";
import { IInsertedResponse } from "../../../../models/mongo-responses/IInsertedResponse";
import PanelEntity from "./PanelEntity";
import { ObjectId } from "mongodb";
import { ICategory } from "../../../../models/registration-dependencies/interfaces/category";

const defaultPanel = {
    title: "",
    subtitle: "",
    categories: [] as any[]
};

const defaultPanelIndexes = {
    title: -1,
    subtitle: -1,
    categories: -1
};

const insertPanelList = async (panelList: IPanel[]) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/create-panels`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        },
        body: JSON.stringify(panelList)
    });
    if (response.status >= 200 && response.status < 300) {
        const json = (await response.json()) as IInsertedResponse;
        return { status: response.status, message: response.statusText, body: json };
    } else {
        return { status: response.status, message: response.statusText, body: { acknowledged: false, insertedCount: 0, insertedIds: {} } };
    }
};

const PanelCreate = ({categories}: {categories:ICategory[]}) => {
    const [step, setStep] = useState(1);
    const [copiedRows, setCopiedRows] = useState([] as string[][]);
    const [newPanels, setNewPanels] = useState([] as IPanelWithCategories[]);
    const [valueIndexes, setValueIndexes] = useState(defaultPanelIndexes);
    const [singlePanel, setSinglePanel] = useState(defaultPanel);
    const [hasHeader, setHasHeader] = useState(true);

    const getFromClipboard = async () => {
        let clipText: string = null as unknown as string;
        try {
            clipText = await navigator.clipboard.readText();
        } catch (error) {
            alert("Data in clipboard is not copied from a spreadsheet.");
        }

        if (clipText) {
            // split into rows
            const clipRows = clipText.split(String.fromCharCode(13));

            let clipCells = [];
            // split rows into columns
            for (let i = 0; i < clipRows.length; i++) {
                clipCells.push(clipRows[i].split(String.fromCharCode(9)));
            }

            if (clipCells.length && clipCells[0].length > 1) {
                setCopiedRows(clipCells);
                setStep(3);
            } else {
                alert("Data in clipboard is not copied from a spreadsheet.");
            }

            console.log(clipCells);
        }
    };

    const getSingle = () => {
        setStep(2);
    };

    const handleSelectChange = ({ target }: { target: HTMLSelectElement }, prop: keyof IPanel) => {
        const idx = [...target.options].find((o) => o.value === target.value)?.dataset.index ?? "-1";
        setValueIndexes({ ...valueIndexes, [prop]: +idx });
    };
    const handleInputChange = ({ target }: { target: HTMLInputElement }, prop: keyof IPanel) => {
        setSinglePanel({ ...singlePanel, [prop]: target.value });
    };
    const handleSubmit = async () => {
        switch (step) {
            case 2:
                setNewPanels([{ ...singlePanel } as IPanelWithCategories]);
                setStep(4);
                break;
            case 3:
                setNewPanels(
                    copiedRows
                        .map((row) => {
                            return {
                                title: valueIndexes.title === -1 ? "" : row[valueIndexes.title],
                                subtitle: valueIndexes.subtitle === -1 ? "" : row[valueIndexes.subtitle],
                                categories:
                                    valueIndexes.categories === -1
                                        ? ([] as ObjectId[])
                                        : categories.filter((c) => row[valueIndexes.categories].includes(c.CategoryName)).map((c) => c._id)
                            } as IPanel;
                        })
                        .slice(hasHeader ? 1 : 0)
                );
                setStep(4);
                break;

            case 4:
                const response = await insertPanelList(newPanels);
                if (response.body.acknowledged) {
                    alert(`Imported ${response.body.insertedCount} panels`);
                } else {
                    alert(response.message);
                }
                break;

            default:
                break;
        }
    };

    return (
        <section className={style["panel-create"]}>
            {(() => {
                switch (step) {
                    case 2:
                        return (
                            <>
                                <article>
                                    {Object.keys(defaultPanel).map((k, idx) => (
                                        <>
                                            <label key={`input-${idx}`}>{k}</label>
                                            <input key={`input-${idx}`} onChange={(e) => handleInputChange(e, k as keyof IPanel)}></input>
                                        </>
                                    ))}
                                </article>
                            </>
                        );

                    case 3:
                        return (
                            <>
                                <article className={style.invert}>
                                    <label>Copied select has header</label>
                                    <input type="checkbox" onChange={(e) => setHasHeader(e.target.checked)}></input>
                                    {Object.keys(defaultPanel).map((k, idx) => (
                                        <>
                                            <label key={`input-${idx}`}>{k}</label>
                                            <select key={`select-${idx}`} onChange={(e) => handleSelectChange(e, k as keyof IPanel)}>
                                                {copiedRows[0].map((cell: string, idx) => (
                                                    <option key={`option-${idx}`} data-index={idx}>{cell}</option>
                                                ))}
                                            </select>
                                        </>
                                    ))}
                                </article>
                            </>
                        );
                    case 4:
                        if (newPanels.length) {
                            return (
                                <>
                                    <article className={style.invert}>
                                        {newPanels.map((panel: IPanelWithCategories, idx: number) => (
                                            <PanelEntity panel={panel} key={`panel-${idx}`}></PanelEntity>
                                        ))}
                                    </article>
                                </>
                            );
                        } else {
                            return <article className={`${style.invert} ${style.center}`}>These records have already been imported.</article>;
                        }

                    default:
                        return (
                            <>
                                <button onClick={getFromClipboard}>Create Panels from Clipboard</button>
                                <button onClick={getSingle}>Create Single Entry</button>
                            </>
                        );
                }
            })()}
            <nav>
                <button onClick={() => setStep(1)}> Reset </button>
                <button onClick={handleSubmit}>Submit</button>
            </nav>
        </section>
    );
};

export default PanelCreate;
