import { faEye, faEyeSlash, faListCheck, faPenToSquare, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ICategoryWithTracks } from "../../../../models/registration-dependencies/interfaces/category";
import { IPanel } from "../../../../models/registration-dependencies/interfaces/panels";
import style from "./category-entity.module.css";
import Dialog from "../../dialog/Dialog";
import BucketSelection, { IEntity } from "../../bucket-selection/BucketSelection";
import { ITrack } from "../../../../models/registration-dependencies/interfaces/tracks";

const CategoryEntity = ({ category, panels, tracks }: { category: ICategoryWithTracks; panels: IPanel[]; tracks: ITrack[] }) => {
    const [viewModel, setViewModel] = useState(category);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<"edit" | "view">("view");
    const [trackModal, setTrackModal] = useState(false);

    const putActive = async (id: string, value: boolean) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/categories/${id}/is_active/bool/${value}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            }
        });
        setLoading(false);
        if (response.status === 200) {
            setViewModel({ ...viewModel, is_active: value });
        }
    };

    const putCategoryName = async (id: string, value: string) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/categories/${id}/CategoryName/string/${value}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            }
        });
        setLoading(false);
        if (response.status === 200) {
            setViewModel({ ...viewModel, CategoryName: value });
            setMode("view");
        }
    };

    const putTrackList = async (id: string, value: IEntity[]) => {
        const trackList = value.map((t) => {
            return { TrackName: t.value, _id: t.id } as ITrack;
        });
        const tracksDb = value.map((t) => t.id);

        const response = await fetch(`${process.env.REACT_APP_BACKEND}/categories/${id}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            },
            body: JSON.stringify({Tracks:tracksDb})
        });
        setLoading(false);
        if (response.status === 200) {
            setViewModel({ ...viewModel, trackList: trackList });
            setTrackModal(false);
        }
    };

    const toggleActive = () => {
        setLoading(true);
        const active = !(viewModel?.is_active ?? true);
        putActive(category._id.toString(), active);
    };

    const changeCategoryName = () => {
        setLoading(true);
        putCategoryName(category._id.toString(), viewModel.CategoryName);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setViewModel({ ...viewModel, CategoryName: e.target.value });
    };

    return (
        <section className={style["category-entity"]} data-active={viewModel.is_active !== false}>
            <article data-role="card">
                {mode === "view" && <h3>{viewModel.CategoryName}</h3>}
                {mode === "edit" && (
                    <label>
                        <input onChange={handleChange} value={viewModel.CategoryName} />
                        <FontAwesomeIcon title="Save Name Change" onClick={changeCategoryName} icon={faSave} />
                    </label>
                )}
                <span>Panel Count: {panels.length}</span>
                <ul>
                    {viewModel.trackList
                        ?.sort((a, b) => a.TrackName.localeCompare(b.TrackName, undefined, { sensitivity: "base" }))
                        .map((t, idx) => (
                            <li key={`track-${idx}`} title={t?.TrackName ?? viewModel.Tracks[idx]}>
                                {t?.TrackName ?? viewModel.Tracks[idx]}
                            </li>
                        ))}
                </ul>
                <div className={style.tools}>
                    <FontAwesomeIcon title="Toggle Active State" onClick={toggleActive} icon={viewModel.is_active !== false ? faEye : faEyeSlash} />
                    <FontAwesomeIcon
                        title="Toggle Edit State"
                        onClick={() => (mode === "view" ? setMode("edit") : setMode("view"))}
                        icon={faPenToSquare}
                        data-danger={mode === "edit"}
                    />
                    <FontAwesomeIcon title="Edit Associated Tracks - Not Implemented Yet!!!" icon={faListCheck} onClick={() => setTrackModal(true)} />
                </div>
            </article>
            <Dialog title="Associate Tracks" openModal={trackModal} closeModal={() => setTrackModal(false)}>
                <BucketSelection
                    label={category.CategoryName}
                    assignedBucket={{
                        label: "Assigned Tracks",
                        entities:
                            category?.trackList?.map((t) => {
                                return { value: t?.TrackName, id: t?._id };
                            }) ?? []
                    }}
                    availableBucket={{
                        label: "Available Tracks",
                        entities: tracks.map((t) => {
                            return { value: t?.TrackName, id: t?._id };
                        })
                    }}
                    onSubmit={(entities: IEntity[]) => putTrackList(category._id.toString(), entities)}
                    onCancel={() => setTrackModal(false)}
                ></BucketSelection>
            </Dialog>
            <data data-loading={loading}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </data>
        </section>
    );
};

export default CategoryEntity;
