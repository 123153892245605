import { useEffect, useState } from "react";
import { IFilter } from "../../../models/filter/IFilter";
import { ICategory, ICategoryWithTracks } from "../../../models/registration-dependencies/interfaces/category";
import { IPanel } from "../../../models/registration-dependencies/interfaces/panels";
import { ITrack } from "../../../models/registration-dependencies/interfaces/tracks";
import CategoryEntity from "../../shared/entities/category-entity/CategoryEntity";
import style from "./categories.module.css";

const filterCategories = (category: ICategoryWithTracks, filter: IFilter) => {
    if ((filter.mode & filterDictionary.active) === filterDictionary.active) {
        if (!(category.is_active !== false)) {
            return false;
        }
    }
    if ((~filter.mode & filterDictionary.active) === filterDictionary.active) {
        if (!(category.is_active === false)) {
            return false;
        }
    }
    if ((filter.mode & filterDictionary.name) === filterDictionary.name) {
        const name = (filter.value.name as string).toLocaleLowerCase();
        if (
            !(
                name === "" ||
                category.CategoryName?.toLocaleLowerCase().includes(name) ||
                category.trackList?.some((t) => t?.TrackName?.toLocaleLowerCase().includes(name)) ||
                category.Tracks.some((t) => t?.toString().toLocaleLowerCase().includes(name))
            )
        ) {
            return false;
        }
    }

    return true;
};

const updateEntities = async (ids: string[], value: boolean) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/categories`, {
        method: "PATCH",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        },
        body: JSON.stringify({
            ids: ids,
            data: {
                is_active: value
            }
        })
    });
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else {
        console.warn(`Failed to ${value ? "activate" : "deactivate"} categories.`);
        return null;
    }
};

const filterDictionary = {
    name: 0b0001,
    active: 0b0010
};

const Categories = () => {
    const [tracks, setTracks] = useState([] as ITrack[]);
    const [categoryResult, setCategoryResult] = useState([] as ICategory[]);
    const [categories, setCategories] = useState([] as ICategoryWithTracks[]);
    const [panels, setPanels] = useState([] as IPanel[]);
    const [filter, setFilter] = useState({ mode: filterDictionary.active, value: {} } as IFilter);

    useEffect(() => {
        async function fetchTrackList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/tracks`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setTracks(json);
            }
        }
        async function fetchCategoryList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/categories`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = (await response.json()) as ICategory[];
                setCategoryResult(json.sort((a, b) => a.CategoryName.localeCompare(b.CategoryName)));
            }
        }
        async function fetchPanelList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/panels`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setPanels(json);
            }
        }
        fetchTrackList();
        fetchCategoryList();
        fetchPanelList();
    }, []);

    useEffect(() => {
        if (categoryResult.length && tracks.length) {
            setCategories(
                categoryResult.map((c) => ({ ...c, trackList: c.Tracks.map((t) => tracks.find((track) => t === track._id)) } as ICategoryWithTracks))
            );
        }
    }, [categoryResult, tracks]);

    const handleDeactivateClick = async () => {
        const inactive_panels = categories
            .map((category) => (category.is_active === false || panels.filter((p) => p.categories.includes(category._id)).length ? null : category))
            .filter((p) => p)
            .map((ip) => ip?._id.toString())
            .filter((ip) => ip) as string[];
        const response = await updateEntities(inactive_panels, false);
        if (response) {
            setCategories(
                categories.map((c) => {
                    return inactive_panels.includes(c._id.toString()) ? { ...c, is_active: false } : c;
                })
            );
        }
    };

    const handleSearchChange = (e: { target: { value: any } }) => {
        if (e.target.value === "") {
            setFilter({ mode: filterDictionary.name & ~filter.mode, value: e.target.value });
        } else {
            setFilter({ mode: filterDictionary.name | filter.mode, value: e.target.value });
        }
    };

    const handleActiveFilterClick = () => {
        if ((filter.mode & filterDictionary.active) === filterDictionary.active) {
            setFilter({ ...filter, mode: filterDictionary.active & ~filter.mode });
        } else {
            setFilter({ ...filter, mode: filterDictionary.active | filter.mode });
        }
    };

    return (
        <section className={style.categories}>
            <ul>
                <li>
                    <input placeholder="🔍 Search Categories" onChange={handleSearchChange} />
                </li>
                <li onClick={handleActiveFilterClick}>{(filter.mode & filterDictionary.active) === filterDictionary.active ? "Active" : "Inactive"}</li>
                <li title="Deactivate categories with no panels" onClick={() => handleDeactivateClick()}>
                    Mass deactivate
                </li>
                <li className={style.count}>Count: {categories.filter((category) => filterCategories(category, filter)).length}</li>
            </ul>
            {categories
                .filter((category) => filterCategories(category, filter))
                .map((category, idx) => (
                    <CategoryEntity
                        category={category}
                        key={`category-${category._id.toString()}-${idx}`}
                        panels={panels.filter((p) => p.categories.includes(category._id))}
                        tracks={tracks}
                    ></CategoryEntity>
                ))}
        </section>
    );
};

export default Categories;
