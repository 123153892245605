import React, { useEffect, useState } from "react";
import style from "./bucket-selection.module.css";
import { ObjectId } from "mongodb";

interface IBucket {
    label: string;
    entities: IEntity[];
}

export interface IEntity {
    id: ObjectId;
    value: string;
}

const BucketSelection = ({
    label,
    assignedBucket,
    availableBucket,
    onSubmit,
    onCancel
}: {
    label: string;
    assignedBucket: IBucket;
    availableBucket: IBucket;
    onSubmit: (entities: IEntity[]) => Promise<void>;
    onCancel: () => void;
}) => {
    const [assignedEntities, setAssignedEntities] = useState(assignedBucket.entities);
    const [availableEntities, setAvailableEntities] = useState(availableBucket.entities.filter((e) => !assignedBucket.entities.find((a) => a === e)));
    const [modifiedEntities, setModifiedEntities] = useState<IEntity[]>([]);

    const handleClick = (type: "available" | "assigned", entity: IEntity) => {
        setModifiedEntities([...modifiedEntities, entity]);
        if (type === "available") {
            setAssignedEntities([...assignedEntities, entity]);
        } else if (type === "assigned") {
            setAssignedEntities(assignedEntities.filter((a) => a !== entity));
        }
    };

    useEffect(() => {
        setAvailableEntities(availableBucket.entities.filter((e) => !assignedEntities.find((a) => a.id === e.id)));
    }, [assignedEntities, availableBucket.entities]);

    return (
        <section className={style["bucket-selection"]}>
            <h1>{label}</h1>
            <fieldset>
                <legend>
                    <h2>{assignedBucket.label}</h2>
                </legend>
                <span>Select an Entity below to remove from assigned.</span>
                <ul>
                    {assignedEntities
                        .filter((a) => a.id)
                        .sort((a, b) => a.id.toString().localeCompare(b.id.toString(), undefined, { sensitivity: "base" }))
                        .map((e) => (
                            <li
                                key={e.id.toString()}
                                className={style.assigned}
                                data-chip={"\uf358"}
                                onClick={() => handleClick("assigned", e)}
                                {...(modifiedEntities.find((m) => m === e) && { [`modified`]: "" })}
                            >
                                {e.value}
                            </li>
                        ))}
                </ul>
            </fieldset>
            <fieldset>
                <legend>
                    <h2>{availableBucket.label}</h2>
                </legend>
                <span>Select an Entity below to add to assigned.</span>
                <ul>
                    {availableEntities
                        .filter((a) => a.id)
                        .sort((a, b) => a.id.toString().localeCompare(b.id.toString(), undefined, { sensitivity: "base" }))
                        .map((e) => (
                            <li
                                key={e.id.toString()}
                                className={style.available}
                                data-chip={"\uf35b"}
                                onClick={() => handleClick("available", e)}
                                {...(modifiedEntities.find((m) => m === e) && { [`modified`]: "" })}
                            >
                                {e.value}
                            </li>
                        ))}
                </ul>
            </fieldset>
            <div>
                <button onClick={onCancel}>Cancel</button>
                <button onClick={() => onSubmit(assignedEntities)}>Submit</button>
            </div>
        </section>
    );
};

export default BucketSelection;
