import { faFileExcel, faIdCard, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { IFilter } from "../../../models/filter/IFilter";
import { IInvite } from "../../../models/invite/invite.interface";
import { IInsertedResponse } from "../../../models/mongo-responses/IInsertedResponse";
import { Registration } from "../../../models/registration.model";
import Dialog from "../../shared/dialog/Dialog";
import InviteCreate from "../../shared/entities/invite-entity/InviteCreate";
import InviteEntity from "../../shared/entities/invite-entity/InviteEntity";
import TableHeader from "../../shared/table-header/TableHeader";
import style from "./invite-list.module.css";

const headers = [
    { value: "Pseudonym", sortColumn: "Credited As Name" },
    { value: "Legal Name", sortColumn: "Last Name IRL" },
    { value: "Email", sortColumn: "Email Address1" },
    { value: "Location", sortColumn: "Panelist City" }
];

const filterDictionary = {
    name: 0b0001,
    exists: 0b0010
};

const createRegistrations = async (inviteList: IInvite[]) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/create-registrations/invite-list`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        },
        body: JSON.stringify(inviteList)
    });
    if (response.status >= 200 && response.status < 300) {
        const json = (await response.json()) as IInsertedResponse;
        return { status: response.status, message: response.statusText, body: json, success: true };
    } else {
        return { status: response.status, message: response.statusText, body: null, success: false };
    }
};

const filterInvites = (invite: IInvite, filter: IFilter, registrations: Registration[]) => {
    if ((filter.mode & filterDictionary.name) === filterDictionary.name) {
        const name = (filter.value["name"] as string).toLocaleLowerCase();
        if (
            !(
                name === "" ||
                invite["Credited As Name"]?.toLocaleLowerCase().includes(name) ||
                invite["Email Address1"]?.toLocaleLowerCase().includes(name) ||
                invite["First Name IRL"]?.toLocaleLowerCase().includes(name) ||
                invite["Last Name IRL"]?.toLocaleLowerCase().includes(name) ||
                invite["Panelist City"]?.toLocaleLowerCase().includes(name) ||
                invite["Panelist State"]?.toLocaleLowerCase().includes(name)
            )
        ) {
            return false;
        }
    }
    if ((filter.mode & filterDictionary.exists) === filterDictionary.exists) {
        if (
            !(
                invite["Email Address1"] &&
                !registrations.find(
                    (r) =>
                        r.PanelistContact.Email?.toLocaleLowerCase().localeCompare(invite["Email Address1"]?.toLocaleLowerCase()) === 0 ||
                        r.guestId === invite["Guest ID"]
                )
            )
        ) {
            return false;
        }
    }

    return true;
};

const InviteList = () => {
    const [inviteList, setInviteList] = useState([] as IInvite[]);
    const [registrations, setRegistrations] = useState([] as Registration[]);
    const [modal, setModal] = useState(false);
    const [createRegModal, setCreateRegModal] = useState(false);
    const [filter, setFilter] = useState({ mode: 0, value: {} } as IFilter);
    const [refresh, setRefresh] = useState(false);
    const [view, setView] = useState("card" as "card" | "table");
    const [scrollYPosition, setScrollYPosition] = useState(0);
    const [sort, setSort] = useState({ column: headers[0], ascending: true });

    useEffect(() => {
        async function fetchInviteList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/invite-list`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = (await response.json()) as IInvite[];
                setInviteList(json);
            }
        }
        async function fetchRegistrations() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/allRegistrations`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setRegistrations(json);
            }
        }
        fetchRegistrations();
        fetchInviteList();
    }, [refresh]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const refreshInvites = () => {
        setRefresh(!refresh);
    };

    const handleNewRegistrations = async () => {
        const response = await createRegistrations(inviteList.filter((invite) => filterInvites(invite, filter, registrations)));
        if (response.success) {
            setCreateRegModal(false);
            alert("Successfully added registrations");
            refreshInvites();
        }
    };

    const handleScroll = () => {
        setScrollYPosition(window.scrollY);
    };

    const handleHeaderClick = (column: { value: string; sortColumn: string }) => {
        if (column.value !== sort.column.value) {
            setSort({ column: column, ascending: true });
        } else {
            setSort({ column: sort.column, ascending: !sort.ascending });
        }
    };

    const handleInviteListReportClick = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/generate/invite-list`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            }
        });
        if (response.status === 200) {
            const blob = await response.blob();
            var fileURL = URL.createObjectURL(blob);
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.download = `invite_list_${new Date().toISOString().substring(0, 10)}.xlsx`;
            fileLink.click();
        }
    };

    const handleSearchChange = (e: { target: { value: any } }) => {
        if (e.target.value === "") {
            setFilter({ ...filter, mode: filterDictionary.name & ~filter.mode });
        } else {
            setFilter({ mode: filterDictionary.name | filter.mode, value: { ...filter.value, name: e.target.value } });
        }
    };

    const handleNoRegClick = () => {
        if ((filter.mode & filterDictionary.exists) === filterDictionary.exists) {
            setFilter({ ...filter, mode: filter.mode & ~filterDictionary.exists });
        } else {
            setFilter({ ...filter, mode: filter.mode | filterDictionary.exists });
        }
    };

    return (
        <section className={style["invite-list"]} data-role={view}>
            <ul>
                <li>
                    <input placeholder="🔍 Search Invites" onChange={handleSearchChange} />
                </li>
                <li onClick={() => setModal(true)}>Add Invite</li>
                <li className={(filter.mode & filterDictionary.exists) === filterDictionary.exists ? style.active : ""} onClick={handleNoRegClick}>
                    No Reg
                </li>
                <li className={(filter.mode & filterDictionary.exists) === filterDictionary.exists ? "" : style.hide} onClick={() => setCreateRegModal(true)}>
                    Create Registrations
                </li>
                <li className={style.right} onClick={handleInviteListReportClick} title="Generate Master List">
                    <FontAwesomeIcon icon={faFileExcel} />
                </li>
                <li onClick={() => setView(view === "card" ? "table" : "card")}>
                    <FontAwesomeIcon icon={view === "card" ? faTable : faIdCard} />
                </li>
                <li className={style.count}>Count: {inviteList.filter((invite) => filterInvites(invite, filter, registrations)).length}</li>
            </ul>
            {view === "table" && (
                <TableHeader headers={headers} handleHeaderClick={handleHeaderClick} sort={sort} background={scrollYPosition > 100}></TableHeader>
            )}
            {inviteList
                .filter((invite) => filterInvites(invite, filter, registrations))
                .sort(
                    (a: any, b: any) =>
                        a[sort.column.sortColumn]?.localeCompare(b[sort.column.sortColumn], "en", { sensitivity: "base" }) * (sort.ascending ? 1 : -1)
                )
                .map((invite, idx) => (
                    <InviteEntity
                        invite={invite}
                        key={`profile-${idx}`}
                        showDelete={
                            (invite["Email Address1"] &&
                                !registrations.find(
                                    (r) =>
                                        r.PanelistContact.Email?.toLocaleLowerCase().localeCompare(invite["Email Address1"]?.toLocaleLowerCase()) === 0 ||
                                        r.guestId === invite["Guest ID"]
                                )) as boolean
                        }
                        refresh={refreshInvites}
                    ></InviteEntity>
                ))}
            <Dialog title="Create Invite" openModal={modal} closeModal={() => setModal(false)}>
                <InviteCreate list={inviteList} registrations={registrations}></InviteCreate>
            </Dialog>
            <Dialog title="Are you sure?" openModal={createRegModal} closeModal={() => setCreateRegModal(false)}>
                <>
                    <span className={style.title}>
                        Are you sure you want to create {inviteList.filter((invite) => filterInvites(invite, filter, registrations)).length} new invite(s).
                    </span>
                    <div>
                        <button onClick={handleNewRegistrations}>Yes</button>
                        <button onClick={() => setCreateRegModal(false)}>No</button>
                    </div>
                </>
            </Dialog>
        </section>
    );
};

export default InviteList;
