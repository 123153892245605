import React from 'react'
import { Registration } from '../../../../models/registration.model';
import { IPanelWithUsers } from '../../containers/profiles-container';
import style from './print-panel.module.css'

const participationType = (panelType: string, moderator: string | undefined, userEmails: string[]) => {
    if (panelType === "Reference Time Slot") {
        return "Reference";
    } else if (moderator === "(M)") {
        return (<>Moderator - <a href={`mailto: ${userEmails.join(',')}`} target="_blank" rel="noreferrer">Send Email to Participants</a></>);
    } else {
        return "Panelist";
    }
}

const getDayOfWeek = (date: string) => {
    return new Date(date).toLocaleDateString("en", { weekday: 'long' });
}

const PrintPanelEntity = ({ registration, userPanels }: { registration: Registration; userPanels: IPanelWithUsers[] }) => {
    return (
        <section className={style.print}>
            <table className={style.panels}>
                <thead>{registration.RegistrationNames.Pseudonym}</thead>
                <tbody>
                <ul>
                    {userPanels.map((panel, idx) => (
                        <li key={`panel-${idx}`}>
                            <fieldset data-ref={panel.panelType === "Reference Time Slot"}>
                                <legend>{panel.panelTitle}</legend>
                                <span>
                                    <label>{panel.location}</label>
                                    {getDayOfWeek(new Date(panel.date).toLocaleDateString())} &nbsp;
                                    {panel.startTime.startsWith("1899") ? new Date(panel.startTime).toLocaleTimeString("en", { timeZone: 'PST', hour12: true }) : panel.startTime}&nbsp;-&nbsp;
                                    {panel.endTime.startsWith("1899") ? new Date(panel.endTime).toLocaleTimeString("en", { timeZone: 'PST', hour12: true }) : panel.endTime}
                                </span>
                                <p className={style["participation-type"]}>
                                    {participationType(panel.panelType, panel.moderator, Object.values(panel.users))}
                                </p>
                                {panel.panelType !== "Reference Time Slot" && <p>
                                    <b>
                                        {panel.fullPanelistList?.split('\n')[0]} - Moderator;&nbsp;
                                    </b>
                                    {panel.allPanelistsButModerator?.split('\n').filter(n => n !== '-').join('; ')}
                                </p>}
                                <p>{panel.panelDescription}</p>
                                {panel.noteToPanelistsmoderator && panel.noteToPanelistsmoderator.trim() !== "[exclude from Program]" && <p>
                                    <b>Notes:</b> {panel.noteToPanelistsmoderator}
                                </p>}
                            </fieldset>
                        </li>
                    ))}
                </ul>
                </tbody>
                <tfoot>
                    Total Panels: {userPanels.filter(panel => panel.panelType !== "Reference Time Slot").length}
                </tfoot>
            </table>
        </section>
    )
}

export default PrintPanelEntity
