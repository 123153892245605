import React, { useEffect, useRef } from "react";
import style from "./dialog.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Dialog = ({ openModal, closeModal, title, children }: { openModal: boolean; closeModal: () => void; title: string; children: JSX.Element }) => {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (openModal) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [openModal]);

    return (
        <dialog ref={ref} onCancel={closeModal} className={style.dialog}>
            <menu>
                <span className={style.title}>{title}</span>
                <FontAwesomeIcon icon={faCircleXmark} onClick={closeModal} />
            </menu>
            <article>{children}</article>
        </dialog>
    );
};

export default Dialog;
