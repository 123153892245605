import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Categories from "./components/pages/categories/Categories";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Home from "./components/pages/home/Home";
import InviteList from "./components/pages/invite-list/InviteList";
import Login from "./components/pages/login/Login";
import Registrations from "./components/pages/registrations/Registrations";
import Tracks from "./components/pages/tracks/Tracks";
import { User } from "./models/User.model";
import Panels from "./components/pages/panels/Panels";
import AllPanelsByUser from "./components/pages/all-panels-by-user/AllPanelsByUser";
const queryClient = new QueryClient();

const App = () => {
    const [user, setUser] = useState<User>(null as unknown as User);

    useEffect(() => {
        async function fetchUser() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/auth/login/success`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setUser(json.user);
            }
        }
        fetchUser();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Navbar user={user}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/registrations" element={user ? <Registrations /> : <></>} />
                        <Route path="/dashboard" element={user ? <Dashboard /> : <></>} />
                        <Route path="/invites" element={user ? <InviteList /> : <></>} />
                        <Route path="/tracks" element={user ? <Tracks /> : <></>} />
                        <Route path="/categories" element={user ? <Categories /> : <></>} />
                        <Route path="/panels" element={user ? <Panels /> : <></>} />
                        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
                        <Route path="/print/panels" element={<AllPanelsByUser/>} />
                    </Routes>
                </Navbar>
            </BrowserRouter>
        </QueryClientProvider>
    );
};

export default App;
