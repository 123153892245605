import { useEffect, useState } from "react";
import { IFilter } from "../../../models/filter/IFilter";
import { ITrack } from "../../../models/registration-dependencies/interfaces/tracks";
import TrackEntity from "../../shared/entities/tracks-entity/TrackEntity";
import style from "./tracks.module.css";

const filterDictionary = {
    name: 0b0001
};

const filterTracks = (track: ITrack, filter: IFilter) => {
    if (filter.mode === 0) {
        return true;
    }

    if (filter.mode & filterDictionary.name) {
        const name = (filter.value.name as string).toLocaleLowerCase();
        if (!track?.TrackName?.toLocaleLowerCase().includes(name)) {
            return false;
        }
    }

    return true;
};

const Tracks = () => {
    const [tracks, setTracks] = useState([]);
    const [filter, setFilter] = useState({ mode: 0, value: {} } as IFilter);

    useEffect(() => {
        async function fetchTrackList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/tracks`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setTracks(json);
            }
        }
        fetchTrackList();
    }, []);

    const handleSearchChange = (e: { target: { value: any } }) => {
        if (e.target.value === "") {
            setFilter({ ...filter, mode: filterDictionary.name & ~filter.mode });
        } else {
            setFilter({ mode: filterDictionary.name | filter.mode, value: { ...filter.value, name: e.target.value } });
        }
    };

    return (
        <section className={style.tracks}>
            <ul>
                <li>
                    <input placeholder="🔍 Search Tracks" onChange={handleSearchChange} />
                </li>
                <li className={style.count}>Count: {tracks.filter((track) => filterTracks(track, filter)).length}</li>
            </ul>
            {tracks
                .filter((track) => filterTracks(track, filter))
                .map((track, idx) => (
                    <TrackEntity track={track} key={`track-${idx}`}></TrackEntity>
                ))}
        </section>
    );
};

export default Tracks;
