import style from "./strategy-login-button.module.css";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type StrategyLoginButtonProps = {
    strategyIcon: IconDefinition;
    strategyName: string;
    strategyOnClick: () => void;
};

const StrategyLoginButton = ({ strategyIcon, strategyName, strategyOnClick }: StrategyLoginButtonProps) => {
    return (
        <section className={style["strategy-login-button"]} data-strategy={strategyName}>
            <button onClick={strategyOnClick}>
                <FontAwesomeIcon icon={strategyIcon} /> <span>Sign in with {strategyName}</span>
            </button>
        </section>
    );
};

export default StrategyLoginButton;
