import { useEffect, useState } from "react";
import { IFilter } from "../../../models/filter/IFilter";
import { ICategory, ICategoryWithTracks } from "../../../models/registration-dependencies/interfaces/category";
import { IPanel, IPanelWithCategories } from "../../../models/registration-dependencies/interfaces/panels";
import { ITrack } from "../../../models/registration-dependencies/interfaces/tracks";
import PanelEntity from "../../shared/entities/panel-entity/PanelEntity";
import style from "./panels.module.css";
import Dialog from "../../shared/dialog/Dialog";
import PanelCreate from "../../shared/entities/panel-entity/PanelCreate";
import ToolButton from "../../shared/tool-button/ToolButton";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const filterDictionary = {
    name: 0b0001
};

const filterPanels = (panel: IPanelWithCategories, filter: IFilter) => {
    if (filter.mode === 0) {
        return true;
    }

    if (filter.mode & filterDictionary.name) {
        const name = (filter.value.name as string).toLocaleLowerCase();
        if (
            !(
                name === "" ||
                panel.title?.toLocaleLowerCase().includes(name) ||
                panel.subtitle?.toLocaleLowerCase().includes(name) ||
                panel.categoryList?.some((c) => c.CategoryName?.toLocaleLowerCase()?.includes(name))
            )
        ) {
            return false;
        }
    }

    return true;
};

const Panels = () => {
    const [panels, setPanels] = useState([] as IPanel[]);
    const [tracks, setTracks] = useState([] as ITrack[]);
    const [categoryResult, setCategoryResult] = useState([] as ICategory[]);
    const [categories, setCategories] = useState([] as ICategoryWithTracks[]);
    const [panelsWithCategories, setPanelsWithCategories] = useState<IPanelWithCategories[]>([]);
    const [filter, setFilter] = useState({ mode: 0, value: {} } as IFilter);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        async function fetchPanelList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/panels`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setPanels(json);
            }
        }
        async function fetchTrackList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/tracks`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setTracks(json);
            }
        }
        async function fetchCategoryList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/categories`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setCategoryResult(json);
            }
        }
        fetchPanelList();
        fetchTrackList();
        fetchCategoryList();
    }, []);

    useEffect(() => {
        if (categoryResult.length && tracks.length) {
            setCategories(
                categoryResult.map((c) => ({ ...c, trackList: c.Tracks.map((t) => tracks.find((track) => t === track._id)) } as ICategoryWithTracks))
            );
        }
    }, [categoryResult, tracks]);

    useEffect(() => {
        if (categories.length && panels.length) {
            setPanelsWithCategories(
                panels.map((p) => {
                    return { ...p, categoryList: p.categories.map((c) => categories.find((c2) => c2._id === c)) } as IPanelWithCategories;
                })
            );
        }
    }, [categories, panels]);

    const handleSearchChange = (e: { target: { value: any } }) => {
        if (e.target.value === "") {
            setFilter({ ...filter, mode: filterDictionary.name & ~filter.mode });
        } else {
            setFilter({ mode: filterDictionary.name | filter.mode, value: { ...filter.value, name: e.target.value } });
        }
    };

    const navigate = useNavigate()
    const handleToolClick = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/print/panels");
    };

    return (
        <section className={style.panels}>
            <article>
                <ul>
                    <li>
                        <input placeholder="🔍 Search Panels" onChange={handleSearchChange} />
                    </li>
                    <li onClick={() => setModal(true)}>Add Panel</li>
                    <li className={style.right} data-tool>
                        <ToolButton toolIcon={faPrint} action={handleToolClick} title="Print Panels" />
                    </li>
                    <li className={style.count}>
                        Count: {panelsWithCategories.filter((panel) => filterPanels(panel, filter)).length}
                    </li>
                </ul>
            </article>
            {panelsWithCategories
                .filter((panel) => filterPanels(panel, filter))
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((panel, idx) => (
                    <PanelEntity panel={panel} key={`track-${idx}`}></PanelEntity>
                ))}
            <Dialog title="Create Panel" openModal={modal} closeModal={() => setModal(false)}>
                <PanelCreate categories={categoryResult}></PanelCreate>
            </Dialog>
        </section>
    );
};

export default Panels;
