import { faArrowDownAZ, faArrowDownZA } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import style from "./table-header.module.css";

const TableHeader = ({
    headers,
    sort,
    handleHeaderClick,
    background
}: {
    headers: { value: string; sortColumn: string }[];
    sort: { column: { value: string; sortColumn: string }; ascending: boolean };
    handleHeaderClick: (header: { value: string; sortColumn: string }) => void;
    background: boolean;
}) => {
    return (
        <section className={style["table-header"]} data-background={background}>
            {headers.map((header) => (
                <span className={style["table-header-cell"]} onClick={() => handleHeaderClick(header)}>
                    {header.value}
                    <FontAwesomeIcon
                        icon={sort.ascending || sort.column.value !== header.value ? faArrowDownAZ : faArrowDownZA}
                        className={sort.column.value === header.value ? style.active : ""}
                    />
                </span>
            ))}
            <span className={style["table-header-cell"]} ></span>
        </section>
    );
};

export default TableHeader;
