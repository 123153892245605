import React, { useEffect, useState } from "react";
import style from "./dropdown-button.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const DropdownButton = ({
    label,
    values,
    isActive,
    activeClass
}: {
    label: string;
    values: { action: () => void; value: string }[];
    isActive: boolean;
    activeClass: string;
}) => {
    const [currentLabel, setCurrentLabel] = useState(label);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setCurrentLabel(label);
    }, [label]);

    return (
        <li className={isActive ? activeClass : ""} title={currentLabel} onClick={() => setShow(!show)}>
            {`${currentLabel} `}
            <FontAwesomeIcon icon={faCaretDown} className="caret-down" />
            <div className={style.options} data-show={show}>
                {values.map((v) => (
                    <span
                        key={encodeURIComponent(v.value)}
                        onClick={() => {
                            v.action();
                            setCurrentLabel(v.value);
                        }}
                        title={v.value}
                    >
                        {v.value}
                    </span>
                ))}
            </div>
            <aside onClick={() => setShow(false)} data-show={show}></aside>
        </li>
    );
};

export default DropdownButton;
