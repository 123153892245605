import changelog from '../../../assests/json/changelog.json';
import style from './home.module.css'

const Home = () => {
    return <section className={style.home}>
        {changelog.map((change, changeIdx) => (<fieldset key={`change-${changeIdx}`}>
            <legend><h2>{change.version}</h2></legend>
            <h3>{change.date}</h3>
            <ul data-role="list">
                {change.items.map((item, itemIdx) => (<li key={`item-${itemIdx}`}>
                    <b>{item.name}</b><p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </li>))}
            </ul>
        </fieldset>))}
    </section>;
};

export default Home;
