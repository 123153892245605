import React, { useEffect, useState } from "react";
import style from "./dashboard.module.css";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Registration } from "../../../models/registration.model";
import { IPanel, IPanelWithCategories } from "../../../models/registration-dependencies/interfaces/panels";
import { ITrack } from "../../../models/registration-dependencies/interfaces/tracks";
import { ICategory, ICategoryWithTracks } from "../../../models/registration-dependencies/interfaces/category";
import Grid from "../../shared/grid/Grid";

interface _IPanelAnswer {
    [key: string]: { title: string; count: number; moderate: number; people: string[] };
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
            labels: {
                color: "rgb(255,255,255)"
            }
        },
        title: {
            display: true,
            text: "",
            color: "rgb(255,255,255)"
        }
    },
    scales: {
        y: {
            ticks: { color: "rgb(255,255,255)" }
        },
        x: {
            ticks: { color: "rgb(255,255,255)" }
        }
    },
    aspectRatio: 1
};

const regLabels = ["pending", "Welcome", "Personal Information", "Travel Information", "not started"];

export interface IDataset {
    label: string;
    data: number[];
    backgroundColor: string;
}

const Dashboard = () => {
    const [registrations, setRegistrations] = useState([] as Registration[]);
    const [panels, setPanels] = useState<IPanel[]>([]);
    const [tracks, setTracks] = useState<ITrack[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categoryWithTracks, setCategoryWithTracks] = useState<ICategoryWithTracks[]>([]);
    const [panelsWithCategories, setPanelsWithCategories] = useState<IPanelWithCategories[]>([]);
    const [regBarData, setRegBarData] = useState({
        labels: regLabels,
        datasets: [{ label: "Registrations by Status", backgroundColor: "rgba(255, 99, 132, 0.5)", data: [0] }]
    } as ChartData<"bar", number[], string>);
    const [pnlGridData, setPnlGridData] = useState([] as string[][]);

    useEffect(() => {
        async function fetchRegistrations() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/registrations`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = (await response.json()) as Registration[];
                setRegistrations(json);
                const jsonData: ChartData<"bar", number[], string> = {
                    labels: regLabels,
                    datasets: json.reduce(
                        (acc: IDataset[], curr): IDataset[] => {
                            const index = regLabels.indexOf(curr.status?.name || "not started");
                            acc[0].data[index]++;
                            return acc;
                        },
                        [{ label: "Registrations by Status", backgroundColor: "rgba(255, 198, 92, 0.7)", data: regLabels.map((l) => 0) }] as IDataset[]
                    )
                } as ChartData<"bar", number[], string>;
                console.log("Registration Chart Data", jsonData, json.length);
                setRegBarData(jsonData);
            }
        }
        async function fetchPanelList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/panels`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setPanels(json);
            }
        }
        async function fetchTrackList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/tracks`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setTracks(json);
            }
        }
        async function fetchCategoryList() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/categories`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setCategories(json);
            }
        }
        fetchRegistrations();
        fetchPanelList();
        fetchTrackList();
        fetchCategoryList();
    }, []);

    useEffect(() => {
        if (categories.length && tracks.length) {
            setCategoryWithTracks(
                categories.map((c) => ({ ...c, trackList: c.Tracks.map((t) => tracks.find((track) => t === track._id)) } as ICategoryWithTracks))
            );
        }
    }, [categories, tracks]);

    useEffect(() => {
        if (categoryWithTracks.length && panels.length) {
            const pnls: IPanelWithCategories[] = panels.map((p) => {
                return { ...p, categoryList: p.categories.map((c) => categoryWithTracks.find((c2) => c2._id === c)) } as IPanelWithCategories;
            });
            setPanelsWithCategories(pnls);
        }
    }, [categoryWithTracks, panels]);

    useEffect(() => {
        if (panelsWithCategories.length && registrations.length) {
            const panelsByAnswer: _IPanelAnswer = registrations
                .filter((r) => r.panelAnswers && r.panelAnswers.length)
                .reduce((answers: _IPanelAnswer, reg) => {
                    if (reg.panelAnswers && reg.panelAnswers.length) {
                        const guestAnswers = reg.panelAnswers
                            .filter((pa) => pa.participate && pa.participate !== "No")
                            .reduce((acc, curr) => {
                                if (acc[curr.panel._id]) {
                                    acc[curr.panel._id].count += 1;
                                    acc[curr.panel._id].moderate += curr.participate === "Moderate" ? 1 : 0;
                                    acc[curr.panel._id].people.push(
                                        reg.RegistrationNames.Pseudonym ?? `${reg.PanelistContact.FirstName} ${reg.PanelistContact.LastName}}`
                                    );
                                } else {
                                    acc[curr.panel._id] = {
                                        title: curr.panel.title,
                                        count: curr.participate !== "No" ? 1 : 0,
                                        moderate: curr.participate === "Moderate" ? 1 : 0,
                                        people: [reg.RegistrationNames.Pseudonym ?? `${reg.PanelistContact.FirstName} ${reg.PanelistContact.LastName}}`]
                                    };
                                }
                                return acc;
                            }, {} as _IPanelAnswer);
                        Object.entries(guestAnswers).forEach((ga) => {
                            const key: string = ga[0];
                            const ans: { title: string; count: number; moderate: number; people: string[] } = ga[1];
                            if (answers[key]) {
                                answers[key].count += 1;
                                answers[key].moderate += ans.moderate;
                                answers[key].people = answers[key].people.concat(ans.people);
                            } else {
                                answers[key] = {
                                    title: ans.title,
                                    count: 1,
                                    moderate: ans.moderate,
                                    people: ans.people
                                };
                            }
                        });
                    }
                    return answers as _IPanelAnswer;
                }, {});

            const gridData = Object.values(panelsByAnswer)
                .sort((a, b) => {
                    return b.moderate - a.moderate;
                })
                .sort((a, b) => {
                    return b.count - a.count;
                })
                .map((d) => {
                    return Object.values(d)
                        .map((i) => i.toString())
                        .filter((_, i, a) => i !== a.length - 1);
                });

            const header = Object.keys(panelsByAnswer ? panelsByAnswer[Object.keys(panelsByAnswer)[0]] : {}).filter((_, i, a) => i !== a.length - 1);
            console.log("Panel Chart Data", header);
            setPnlGridData([header, ...gridData]);
        }
    }, [panelsWithCategories, registrations]);

    if (!registrations) return null;

    return (
        <section className={style.dashboard}>
            <Bar height="400" width="1000" options={options} data={regBarData} />
            <Grid data={pnlGridData}></Grid>
        </section>
    );
};

export default Dashboard;
