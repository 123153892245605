import { IPanelWithCategories } from "../../../../models/registration-dependencies/interfaces/panels";
import style from "./panel-entity.module.css";

const TrackEntity = ({ panel }: { panel: IPanelWithCategories }) => {
    return (
        <section className={style["panel-entity"]} data-id={panel._id}>
            <article data-role="card">
                <h3>{panel.title}</h3>
                <h5>{panel.subtitle}</h5>
                <ul>
                    {panel.categoryList?.map((c, idx) => (
                        <li key={`category-${idx}`}>{c.CategoryName}</li>
                    ))}
                </ul>
            </article>
        </section>
    );
};

export default TrackEntity;
