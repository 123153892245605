import { useState } from "react";
import { User } from "../../../models/User.model";
import style from "./user-avatar.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";

function UserAvatar({ user }: { user: User }) {
    const [menuActive, setMenuActive] = useState(false);
    return (
        <button className={style["user-avatar"]} onClick={() => setMenuActive(!menuActive)}>
            {user.photo ? (
                <img src={user.photo} alt="user avatar" data-active={menuActive} />
            ) : (
                <span className={style["default-avatar"]}>
                    <FontAwesomeIcon icon={faUser} />
                </span>
            )}

            <article
                className={`${style["click-handler"]} ${menuActive ? style.open : ""}`}
                onClick={() => setMenuActive(false)}
                data-active={menuActive}
            ></article>
            <article className={menuActive ? style.active : ""} data-role="menu">
                <ul>
                    <li>
                        <Link to="/profile">
                            {user.photo ? (
                                <img src={user.photo} alt="user avatar" data-active={menuActive} />
                            ) : (
                                <span className={style["default-avatar"]}>
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            )}
                            {user.displayName}
                        </Link>
                    </li>
                    <li>
                        <a href={`${process.env.REACT_APP_BACKEND}/auth/logout`}>
                            <FontAwesomeIcon icon={faArrowRightFromBracket} /> Log Out
                        </a>
                    </li>
                </ul>
            </article>
        </button>
    );
}

export default UserAvatar;
