import style from "./style.module.css";
import { NavLink } from "react-router-dom";
import { User } from "../../models/User.model";
import UserAvatar from "../shared/user-avatar/UserAvatar";

function Navbar({ user, children }: { user: User, children: JSX.Element }) {
    console.log("user:", user);
    return (
        <>
            <section className={style.navbar}>
                <ul data-role="navigation">
                    <li>
                        <NavLink className={style.logo} to="/"></NavLink>
                    </li>
                    <li>{process.env.REACT_APP_NAME}</li>
                </ul>
                <ul data-role="information">
                    <li>{user && <NavLink to="/dashboard">Dashboard</NavLink>}</li>
                    <li>{user && <NavLink to="/registrations">Registrations</NavLink>}</li>
                    <li>{user && <NavLink to="/invites">Invites</NavLink>}</li>
                    <li>{user && <NavLink to="/tracks">Tracks</NavLink>}</li>
                    <li>{user && <NavLink to="/categories">Categories</NavLink>}</li>
                    <li>{user && <NavLink to="/panels">Panels</NavLink>}</li>
                    <li>{user ? <UserAvatar user={user} /> : <NavLink to="/login">Login</NavLink>}</li>
                </ul>
            </section>
            {children}
        </>
    );
}

export default Navbar;
