import React, { useEffect, useState } from "react";
import ProfileEntity from "../entities/profile-entity/ProfileEntity";
import style from "./profiles-container.module.css";
import { Registration } from "../../../models/registration.model";
import { IFilter } from "../../../models/filter/IFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract, faFileExcel, faIdCard, faTable } from "@fortawesome/free-solid-svg-icons";
import TableHeader from "../table-header/TableHeader";
import DropdownButton from "../dropdown-button/DropdownButton";
import Dialog from "../dialog/Dialog";
import WebsitePreviewEntity from "../entities/website-preview-entity/WebsitePreviewEntity";
import { IUserPanel } from "../../../models/user-panel/IUserPanel";
import { faWordpressSimple } from "@fortawesome/free-brands-svg-icons";

const headers = [
    { value: "Pseudonym", sortColumn: "RegistrationNames.Pseudonym" },
    { value: "Email", sortColumn: "PanelistContact.Email" },
    { value: "Last Modified Date", sortColumn: "status.date" },
    { value: "Bio Status", sortColumn: "BioAndSocials.bio.length" },
    { value: "Registration Status", sortColumn: "status.name" }
];

const filterDictionary = {
    name: 0b0001,
    status: 0b0010,
    attend: 0b0100
};

export interface IPanelWithUsers extends IUserPanel {
    users: { [key: string]: string };
}

const getPanelList = (userPanels: IUserPanel[], profileModalRegistration: Registration, registrations: Registration[]) => {
    return userPanels
        .filter((up) => up.creditedAsName === profileModalRegistration.RegistrationNames.Pseudonym)
        .map((pl) => {
            return {
                ...pl,
                users: registrations
                    .filter((r) => pl.allPanelistsButModerator?.split("\n").includes(r.RegistrationNames.Pseudonym))
                    .reduce((acc, reg) => {
                        acc[reg.RegistrationNames.Pseudonym] = reg.PanelistContact.Email;
                        return acc;
                    }, {} as { [key: string]: string })
            };
        });
};

const filterRegistrations = (registration: Registration, filter: IFilter) => {
    if (filter.mode === 0) {
        return true;
    }

    if ((filter.mode & filterDictionary.status) === filterDictionary.status) {
        if (!(filter.value.status === "" || (registration.status?.name ?? "not started") === filter.value.status)) {
            return false;
        }
    }
    if ((filter.mode & filterDictionary.name) === filterDictionary.name) {
        const name = (filter.value.name as string).toLocaleLowerCase();
        if (
            !(
                name === "" ||
                registration.PanelistContact?.FirstName?.toLocaleLowerCase().includes(name) ||
                registration.PanelistContact?.LastName?.toLocaleLowerCase().includes(name) ||
                registration.PanelistContact?.Email?.toLocaleLowerCase().includes(name) ||
                registration.RegistrationNames?.Pseudonym?.toLocaleLowerCase().includes(name)
            )
        ) {
            return false;
        }
    }
    if ((filter.mode & filterDictionary.attend) === filterDictionary.attend) {
        const truthy = filter.value.attend as boolean;
        if (!truthy) {
            if (!(registration.status?.name === "Travel Information" && !registration.TravelInfo?.CanAttend)) {
                return false;
            }
        } else {
            if (!registration.TravelInfo?.CanAttend) {
                return false;
            }
        }
    }

    return true;
};

export default function ProfilesContainer() {
    const [registrations, setRegistrations] = useState([] as Registration[]);
    const [userPanels, setUserPanels] = useState([] as IUserPanel[]);
    const [filter, setFilter] = useState({ mode: 0, value: {} } as IFilter);
    const [search, setSearch] = useState("");
    const [view, setView] = useState("card" as "card" | "table");
    const [scrollYPosition, setScrollYPosition] = useState(0);
    const [sort, setSort] = useState({ column: headers[0], ascending: true });
    const [refresh, setRefresh] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [profileModalRegistration, setProfileModalRegistration] = useState(null as unknown as Registration);
    const [panelList, setPanelList] = useState([] as IPanelWithUsers[]);

    const refreshRegistrations = () => {
        setRefresh(!refresh);
        // setFilter({ mode: 0, value: {} });
    };

    React.useEffect(() => {
        async function fetchRegistrations() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/allRegistrations`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setRegistrations(json);
            }
        }

        async function getUserPanels() {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/collection/user-panels`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            if (response.status === 200) {
                const json = await response.json();
                setUserPanels(json);
            }
        }

        fetchRegistrations();
        getUserPanels();
    }, [refresh]);

    useEffect(() => {
        if (profileModalRegistration && userPanels && registrations) {
            setPanelList(getPanelList(userPanels, profileModalRegistration, registrations));
        }
    }, [profileModalRegistration, userPanels, registrations]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleSearch = ({ target }: { target: HTMLInputElement }) => {
        setSearch(target.value);
        if (target.value === "") {
            setFilter({ ...filter, mode: filterDictionary.name & ~filter.mode });
        } else {
            setFilter({ mode: filterDictionary.name | filter.mode, value: { ...filter.value, name: target.value } });
        }
    };

    const handleScroll = () => {
        setScrollYPosition(window.scrollY);
    };

    const handleHeaderClick = (column: { value: string; sortColumn: string }) => {
        if (column.value !== sort.column.value) {
            setSort({ column: column, ascending: true });
        } else {
            setSort({ column: sort.column, ascending: !sort.ascending });
        }
    };

    const handleMasterListReportClick = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/generateMasterList`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            }
        });
        if (response.status === 200) {
            const blob = await response.blob();
            var fileURL = URL.createObjectURL(blob);
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.download = `master_list_${new Date().toISOString().substring(0, 10)}.xlsx`;
            fileLink.click();
        }
    };

    const handleFollowUpReportClick = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/generate/follow-up-report`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true"
            }
        });
        if (response.status === 200) {
            const blob = await response.blob();
            var fileURL = URL.createObjectURL(blob);
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.download = `follow_up_report_${new Date().toISOString().substring(0, 10)}.xlsx`;
            fileLink.click();
        }
    };

    const handleAttendClick = (value: boolean) => {
        setFilter({ mode: filter.mode | filterDictionary.attend, value: { ...filter.value, attend: value } });
    };

    const handlePushToWordpressClick = async () => {
        const confirmUpdate = window.confirm("Are you sure you want to update the wordpress site?");
        if (!confirmUpdate) {
            return;
        }

        const responses = [];
        responses.push(
            await fetch(`${process.env.REACT_APP_BACKEND}/wordpress/guests`, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            })
        );
        responses.push(
            await fetch(`${process.env.REACT_APP_BACKEND}/wordpress/panel-schedule`, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            })
        );
        responses.push(
            await fetch(`${process.env.REACT_APP_BACKEND}/wordpress/tracks`, {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                }
            })
        );

        if (responses.filter((r) => r.status !== 200).length > 0) {
            alert("Error updating Wordpress please report this to the system administrator.");
        } else {
            alert("Wordpress successfully updated.");
        }
    };

    if (!registrations) return null;

    const getItem = (a: Registration, sortColumn: string) => {
        let item = a;
        sortColumn.split(".").forEach((a) => (item ? (item = item[a]) : undefined));
        return item + "";
    };

    const getButtonValues = () => {
        return [...new Set(registrations.sort((a, b) => (a?.status?.level ?? -1) - (b?.status?.level ?? -1)).map((reg) => reg?.status?.name))]
            .map((l) => l ?? "not started")
            .map((s) => {
                return { value: s, action: () => setFilter({ mode: filter.mode | filterDictionary.status, value: { ...filter.value, status: s } }) };
            });
    };

    return (
        <section className={style.container} data-role={view}>
            <article>
                <ul>
                    <li>
                        <input placeholder="🔍 Search Registrations" onChange={handleSearch} value={search} />
                    </li>
                    <li className={filter.mode === 0 ? style.active : ""} onClick={() => setFilter({ ...filter, mode: 0 })}>
                        All
                    </li>
                    <DropdownButton
                        values={getButtonValues()}
                        label={"status"}
                        isActive={(filter.mode & filterDictionary.status) === filterDictionary.status}
                        activeClass={style.active}
                    ></DropdownButton>
                    <li
                        className={(filter.mode & filterDictionary.attend) === filterDictionary.attend && filter.value.attend ? style.active : ""}
                        onClick={() => handleAttendClick(true)}
                    >
                        Can Attend
                    </li>
                    <li
                        className={(filter.mode & filterDictionary.attend) === filterDictionary.attend && !filter.value.attend ? style.active : ""}
                        onClick={() => handleAttendClick(false)}
                    >
                        Can't Attend
                    </li>
                    <li className={style.right} onClick={handleFollowUpReportClick} title="Generate Follow Up List">
                        <FontAwesomeIcon icon={faFileContract} />
                    </li>
                    <li onClick={handleMasterListReportClick} title="Generate Master List">
                        <FontAwesomeIcon icon={faFileExcel} />
                    </li>
                    <li onClick={() => setView(view === "card" ? "table" : "card")}>
                        <FontAwesomeIcon icon={view === "card" ? faTable : faIdCard} />
                    </li>
                    <li onClick={handlePushToWordpressClick} title="Update Wordpress Site">
                        <FontAwesomeIcon icon={faWordpressSimple} />
                    </li>
                    <li className={style.count}>Count: {registrations.filter((reg) => filterRegistrations(reg, filter)).length}</li>
                </ul>
            </article>
            {view === "table" && (
                <TableHeader headers={headers} handleHeaderClick={handleHeaderClick} sort={sort} background={scrollYPosition > 100}></TableHeader>
            )}
            {registrations
                .filter((reg) => filterRegistrations(reg, filter))
                .sort((a, b) => getItem(a, sort.column.sortColumn)?.localeCompare(getItem(b, sort.column.sortColumn)) * (sort.ascending ? 1 : -1))
                .map((reg, idx) => (
                    <ProfileEntity
                        registration={reg}
                        key={`profile-${idx}`}
                        refresh={refreshRegistrations}
                        setShowProfileModal={setShowProfileModal}
                        setProfileModalRegistration={setProfileModalRegistration}
                    ></ProfileEntity>
                ))}
            <Dialog title="Web Profile Preview" openModal={showProfileModal} closeModal={() => setShowProfileModal(false)}>
                <WebsitePreviewEntity registration={profileModalRegistration} userPanels={panelList}></WebsitePreviewEntity>
            </Dialog>
        </section>
    );
}
