import { faFacebook, faInstagram, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Registration } from "../../../../models/registration.model";
import style from "./website-preview-entity.module.css";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPanelWithUsers } from "../../containers/profiles-container";

const formatSocialUrl = (url: string) => {
    return url.startsWith("http") ? url : url ? `https://${url}` : "";
};

const participationType = (panelType: string, moderator: string | undefined, userEmails: string[]) => {
    if (panelType === "Reference Time Slot") {
        return "Reference";
    } else if (moderator === "(M)") {
        return (<>Moderator - <a href={`mailto: ${userEmails.join(',')}`} target="_blank" rel="noreferrer">Send Email to Participants</a></>);
    } else {
        return "Panelist";
    }
}

const WebsitePreviewEntity = ({ registration, userPanels }: { registration: Registration; userPanels: IPanelWithUsers[] }) => {
    const imageSrc = registration?.imageUploaded ? "https://register.soonercon.com/api" + registration.imageHref : `/user.png`;

    return (
        <section className={style["web-preview"]}>
            <img src={imageSrc} alt="User profile"></img>
            <h1>{registration?.RegistrationNames.Pseudonym}</h1>
            <p dangerouslySetInnerHTML={{ __html: registration?.BioAndSocials.bio }}></p>
            <article className={style.social}>
                <ul>
                    {[
                        { url: registration?.BioAndSocials.facebookUrl, icon: faFacebook },
                        {
                            url: registration?.BioAndSocials.instagramHandle ? `instagram.com/${registration.BioAndSocials.instagramHandle}` : "",
                            icon: faInstagram
                        },
                        { url: registration?.BioAndSocials.tiktokHandle ? `tiktok.com/@${registration.BioAndSocials.tiktokHandle}` : "", icon: faTiktok },
                        { url: registration?.BioAndSocials.twitterHandle ? `x.com/${registration.BioAndSocials.twitterHandle}` : "", icon: faTwitter },
                        { url: registration?.BioAndSocials.website1Url, icon: faGlobe },
                        { url: registration?.BioAndSocials.website2Url, icon: faGlobe },
                        { url: registration?.BioAndSocials.website3Url, icon: faGlobe }
                    ]
                        .filter((s) => s.url)
                        .map((social, idx) => (
                            <li key={`social-${idx}`}>
                                <a href={formatSocialUrl(social.url)} target="_blank" rel="noreferrer" referrerPolicy="origin">
                                    <FontAwesomeIcon icon={social.icon}></FontAwesomeIcon>
                                </a>
                            </li>
                        ))}
                </ul>
            </article>
            <article className={style.panels}>
                <ul>
                    {userPanels.map((panel, idx) => (
                        <li key={`panel-${idx}`}>
                            <fieldset data-ref={panel.panelType === "Reference Time Slot"}>
                                <legend>{panel.panelTitle}</legend>
                                <span>
                                    <label>{panel.location}</label>
                                    {new Date(panel.date).toLocaleDateString()} :&nbsp;
                                    {panel.startTime.startsWith("1899") ? new Date(panel.startTime).toLocaleTimeString("en", { timeZone: 'PST', hour12: true }) : panel.startTime}&nbsp;-&nbsp;
                                    {panel.endTime.startsWith("1899") ? new Date(panel.endTime).toLocaleTimeString("en", { timeZone: 'PST', hour12: true }) : panel.endTime}
                                </span>
                                <p className={style["participation-type"]}>
                                    {participationType(panel.panelType, panel.moderator, Object.values(panel.users))}
                                </p>
                                {panel.panelType !== "Reference Time Slot" && <p>
                                    <b>
                                        {panel.fullPanelistList?.split('\n')[0]} - Moderator;&nbsp;
                                    </b>
                                    {panel.allPanelistsButModerator?.split('\n').filter(n => n !== '-').join('; ')}
                                </p>}
                                <p>{panel.panelDescription}</p>
                            </fieldset>
                        </li>
                    ))}
                </ul>
            </article>
        </section>
    );
};

export default WebsitePreviewEntity;
