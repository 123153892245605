import React from 'react'
import style from './invite-entity.module.css'
import { IInvite } from '../../../../models/invite/invite.interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

async function deleteInvite(id: string) {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/invite-list/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true"
        }
    });
    if (response.status === 200) {
        const json = await response.json();
        return (json.user);
    }
}

const handleToolClick = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>, toolName: string, invite: IInvite, refresh?: () => void) => {
    e.preventDefault();

    switch (toolName) {
        case "delete":
            const response = window.confirm("Are you sure you what to delete this record?");
            if(response) {
                if(invite._id) {
                    await deleteInvite(invite._id.toString());
                    refresh && refresh();
                } else {
                    alert("Could not locate invite.");
                }
            }
            break;
    
        default:
            break;
    }
}


const InviteEntity = ({invite, showDelete, refresh}: {invite:IInvite, showDelete: boolean, refresh?: () => void}) => {
    return (
        <section className={style.entity}>
                <article data-role="card">
                    <h5>{invite['Credited As Name']}&nbsp;</h5>
                    <span>{[invite['Last Name IRL'], invite['First Name IRL']].filter(n => n).join(', ')}&nbsp;</span>
                    <span>{invite['Email Address1']}&nbsp;</span>
                    <h6>{[invite['Panelist City'], invite['Panelist State']].filter(p => p).join(', ')}&nbsp;</h6>
                    <div className={style.tools}>
                        {showDelete && <FontAwesomeIcon icon={faTrash} onClick={(e) => handleToolClick(e, "delete", invite, refresh)}></FontAwesomeIcon>}
                    </div>
                </article>
        </section>
    )
}

export default InviteEntity
