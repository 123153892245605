import React from 'react'
import ProfilesContainer from '../../shared/containers/profiles-container'

const Registrations = () => {
  return (
    <ProfilesContainer></ProfilesContainer>
  )
}

export default Registrations
